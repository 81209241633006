@import "../../Theme/variables";

.MuiToolbar-root {
  img {
    width:120px;
  }
}

.Appbar {
  .datetime {
    text-align: right;
    width: 100%;
    svg {
      position: relative;
      top:2px;
      left:3px;
    }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .Appbar {
    .datetime {
      display: none;
    }
  }
}
