input[type="button"],
.button {
  background-color: theme-color('white');
  border: 2px solid theme-color('primary');
  border-radius: 0.3em;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  outline: none;
  padding: 0.6em 0.6em;

  &:focus {
    border-color: theme-color('primary');
  }
}

input[disabled],
select[disabled] {
  background-color: theme-color('background');
  border-color: theme-color('background');
  color: theme-color('black');
  cursor: not-allowed;
}

.button--disabled {
  border-color: theme-color('background');
  background-color: theme-color('background');
  color: theme-color('background', 'medium');
  cursor: not-allowed;
}

.button--primary {
  background-color: theme-color('primary');
  color: theme-color('white');
}

.button--secondary {
  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
}

.button--link {
  background: none;
  border: none;
  color: theme-color('white');
}
