@import url('https://fonts.googleapis.com/css?family=Open+Sans');
body {
  margin: 0;
  overflow-y: auto !important;
  padding-right: initial !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before {
  content: "mobile";
  display: none;  
}

@media (min-width: 900px) {
  body::before {
    content: "tablet"; 
  }
}  

@media (min-width: 1200px) {
  body::before {
    content: "desktop";
  }
}

body.no-scroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[class^="Cell-dayView"] {
  visibility: hidden;
}
