@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideIntoView {
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes modalSlideIntoView {
  from {
    transform: translateY(800px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes shine-lines{
  0%{
    background-position: -200px
  }
  100% {
    background-position: 400px
  }
}
