.overlay {
  background-color: rgba(0, 0, 0, .2);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999999;
}
