body {
    background-color: rgb(253, 252, 252);
}

.Rectangle {
    width: 1902px;
    height: 50px;
    margin: 0 1px 0 0;
    padding: 9px 30px 9px 32px;
    background-color: #235789;
  }
 
.Bitmap {
    width: 155px;
    height: 30px;
    margin: 9px 1206px 11px 0;
  }  

  .Today {
    width: 56px;
    height: 22px;
    margin: 14px 11px 14px 0;
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    justify-content: center;
    text-align: center;
    background-color: #f9f9fa
  }

.floatcontainer {
    float: right;
}
.inner {
    border:1px solid white;
    position:fixed;
}
.floatcontainer, .inner{
    width: 50px;
}

  /* Float the link section to the right */
.header-right {
  float: right;
}  

.header-container {
    height: 60px;
    background-color: #fdfdfd;
    box-shadow: 0 2px 4px 0 rgba(69, 69, 69, 0.5);
    position: relative;
    z-index: 1;
    .nav-colored {
        background-color: transparent;
        padding-top: 20px;
    }
    .header {
        background-color: transparent;
        padding: 20px 0px 15px;
        ul li {
            opacity: 0.6;
        }
        .active {
            opacity: 1;
        }
    }
}

md-sidenav {
    .sidenav-toolbar {
        min-height: 60px;
        height: 60px;
        background-color: #235789;
        .sidenav-toolbar-tools {
            color: #FCFCFC;
        }
    }
    a {
        font-size: 16px;
    }
}
