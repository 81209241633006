@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/loading';
@import '../../Theme';

.login-norm {
  height: 100%;
  position: relative;
}

.login-norm__title {
  border-bottom: 1px solid #979797;
  color: #f09732;
  font-size: 2.0rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 1em 1.3em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}

.login-norm__subtitle {
  color: var(--app-color-dark-shade);
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 2em 0em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;

  }
}

.login-norm__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.MuiInputBase-input {
  border:var(--app-color-dark-shade) !important;
  background-color: transparent !important;
  color:var(--app-color-dark-shade);
}
.login-norm__input {
  width: 100%;
  border:var(--app-color-dark-shade) !important;

  > label {
    transform: translate(14px, 14px) scale(1);
    color:var(--app-color-dark-shade);
  }
}

.login-norm__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1.5em;
  text-align: left;
  visibility: hidden;
}

.login-norm__error--visible {
  visibility: visible;
 
  &:last-of-type {
    margin-bottom: 0;
  }
}

.login-norm__error--no-margin {
  margin-bottom: 0;
}

.login-norm__forgotpw {
  background: none;
  border: none;
  display: block;
  color: #00acea;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.5em 0 1.5em;
  outline: none;
}

.login-norm__login {
  @extend .button;
  @extend .button--primary;
  background-color: var(--app-color-primary);
  color: var(--app-color-light-bright);
  border:none;
  text-transform: uppercase;
  font-weight: normal;
  width: 100%;
  padding: 16px 16px;
  transition:all .1s linear;
  box-shadow: none !important;

  &:disabled {
    @extend .button--disabled;
  }
}

.login-norm__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 40%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.login-norm__illustration {
  transform: scaleX(-1);
  width: 100%;
}


@media only screen and (max-width: $breakpoint-sm) {
  .login__content{
    max-width: 100vw;
    width: 100vw;
  }
}