@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/forms';
@import '../../Styles/buttons';

.login {

  position: fixed;
  left:0;
  width:100%;
  height:100%;
  z-index: 20;
  background:var(--app-color-light);
  height: calc(var(--vh, 1vh) * 100);


  .login__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    padding:50px 20px 30px;
    min-height: 300px;
    border:1px solid var(--app-color-light);
    background-color:var(--app-color-light-bright);
    border-radius: 10px;

    .login-norm__subtitle {
      text-align: center;
    }

    button {
      margin-top:20px;
      padding: 12px;
    }
  }
}
