@import "variables";

html, body, #gatsby-focus-wrapper, .App {
  margin:0;
  padding: 0;
  background-color:var(--app-color-light);
  min-height: 100%;
  min-height:100vh;
}

.MuiToolbar-regular {
  min-height: $toolbarheight !important;
}

.MuiAlert-standardInfo {
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
  position: absolute;
  z-index: 9999;
  left:50%;
  top: 7px;
  box-shadow: 1px 0px 19px rgba(0, 0, 0, 0.15) !important;
  width: 260px;
  margin-left: -130px;
  text-align: left;
}

.App {
  margin:0;
  margin-left:var(--side-width);
  background-color:var(--app-color-light);
  color:var(--app-text-color);
  height:100%;
}

.m {
  &--b {
    &--sm {
      margin-bottom:20px !important;
    }
  }
  &--l {
    &--sm {
      margin-left:20px !important;
    }
  }
}
.fr {
  float: right;
}
.btn-back {
  cursor: pointer;
  display: inline-block;
  max-width:40px;
  width:40px;
  height:40px;
  position: absolute;
  left: 0px;
  top: -3px;
}
.btn-loading-holder {
  position: relative;
  top: 11px;
  .btn-loading {
    position: absolute;
    right:20px;
    top:10px;
    .spinner-invert {
      color:var(--app-color-light-bright);
    }
  }
}

.btn-close {
  cursor: pointer;
  position: absolute;
  right:0;
  padding:6px;
}
.btn-refresh, btn-search {
  width:50px;
  height:40px;
  min-width:50px !important;
  padding:0 !important;
  margin:0 !important;
  right:0;
  font-size: 20px !important;
  color: var(--app-color-primary);
  cursor: pointer;
  &:hover {
    background-color: var(--app-color-light-bright) !important;
  }
  &:active {
    color: $orange !important;
  }
}
.btn-loadmore {
  display: block !important;
}

.container {
  padding: 50px 20px 20px 20px;
  margin: 0 auto;
  text-align: center;

  &.no-gutter {
    padding: 50px 0px 20px 0px;
  }
}

.row {
  display:flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  list-style-type: none;
  margin:0;
  padding:0;

  li{
    margin:0;
    padding:0;
  }

  &__start {
    justify-content: flex-start;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


@media (max-width: $breakpoint-sm) {
  .App {
    margin-left:0;
  }
}
