/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */



$primary: #235789;
$primaryHighlight: #40C4FF;
$yellow: #FBBC04;
$green: #40BC00;
$purple: #9E01F8;
$orange: #EE9439;

$font-primary: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$gray: #EFEFEF;
$gray-lt: rgba(0,0,0,.05);
$gray-md: #cccccc;
$gray-md-dark: #aaaaaa;
$gray-md-dark: #aaaaaa;
$gray-dark: #868993;
$white: #ffffff;
$black: #232323;

$messageListWidth: 380px;
$breakpoint-xs: 750px;
$breakpoint-slim: 400px;
$breakpoint-sm: 1050px;

$toolbarheight:64px;


/** Ionic CSS Variables **/
:root {

  --app-animation-speed:.24s;

  --side-min-width: 270px;
  --side-max-width: 270px;
  --side-width: 270px;

  /** primary **/
  --app-color-primary: #235789;
  --app-color-primary-rgb: 56, 128, 255;
  --app-color-primary-contrast: #ffffff;
  --app-color-primary-contrast-rgb: 255, 255, 255;
  --app-color-primary-shade: #327DC6;
  --app-color-primary-tint: #4c8dff;
  --app-color-primary-light: #ACD3F9;

  --app-color-green: #20B772;
  --app-color-tag-green: #EBF7F1;
  --app-color-red: #FF5408;
  --app-color-yellow-p: #FFF9DE;
  --app-color-green-p: #EBF7F1;
  --app-color-royal: #152ABF;

  /** secondary **/
  --app-color-secondary: #3dc2ff;
  --app-color-secondary-rgb: 61, 194, 255;
  --app-color-secondary-contrast: #ffffff;
  --app-color-secondary-contrast-rgb: 255, 255, 255;
  --app-color-secondary-shade: #36abe0;
  --app-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --app-color-tertiary: #5260ff;
  --app-color-tertiary-rgb: 82, 96, 255;
  --app-color-tertiary-contrast: #ffffff;
  --app-color-tertiary-contrast-rgb: 255, 255, 255;
  --app-color-tertiary-shade: #4854e0;
  --app-color-tertiary-tint: #6370ff;

  /** success **/
  --app-color-success: #20B772;
  --app-color-success-rgb: 45, 211, 111;
  --app-color-success-contrast: #ffffff;
  --app-color-success-contrast-rgb: 255, 255, 255;
  --app-color-success-shade: #28ba62;
  --app-color-success-tint: #42d77d;

  /** warning **/
  --app-color-warning: #ffc409;
  --app-color-warning-rgb: 255, 196, 9;
  --app-color-warning-contrast: #000000;
  --app-color-warning-contrast-rgb: 0, 0, 0;
  --app-color-warning-shade: #e0ac08;
  --app-color-warning-tint: #ffca22;

  /** danger **/
  --app-color-danger: #FF5408;
  --app-color-danger-rgb: 235, 68, 90;
  --app-color-danger-contrast: #ffffff;
  --app-color-danger-contrast-rgb: 255, 255, 255;
  --app-color-danger-shade: #cf3c4f;
  --app-color-danger-tint: #ed576b;

  /** dark **/
  --app-color-dark: #222428;
  --app-color-dark-rgb: 34, 36, 40;
  --app-color-dark-contrast: #ffffff;
  --app-color-dark-contrast-rgb: 255, 255, 255;
  --app-color-dark-shade: #1e2023;
  --app-color-dark-tint: #383a3e;

  /** medium **/
  --app-color-medium: #92949c;
  --app-color-medium-rgb: 146, 148, 156;
  --app-color-medium-contrast: #ffffff;
  --app-color-medium-contrast-rgb: 255, 255, 255;
  --app-color-medium-shade: #808289;
  --app-color-medium-tint: #9d9fa6;

  /** light **/
  --app-color-light-bright: #ffffff;
  --app-color-light: #f4f5f8;
  --app-color-light-rgb: 244, 245, 248;
  --app-color-light-contrast: #000000;
  --app-color-light-contrast-rgb: 0, 0, 0;
  --app-color-light-shade: #d7d8da;
  --app-color-light-tint: #f5f6f9;

  /** text color **/
  --app-text-color: #000000;

  /** transition **/
  --app-hover-transition:1s;

  --svg-invert: invert(0);
}


@media (min-width: var(--min-width)) {

}


@mixin dark-mode {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  --app-text-color: #ffffff;

  --app-color-primary: #428cff;
  --app-color-primary-rgb: 66,140,255;
  --app-color-primary-contrast: #ffffff;
  --app-color-primary-contrast-rgb: 255,255,255;
  --app-color-primary-shade: #3a7be0;
  --app-color-primary-tint: #5598ff;

  --app-color-secondary: #50c8ff;
  --app-color-secondary-rgb: 80,200,255;
  --app-color-secondary-contrast: #ffffff;
  --app-color-secondary-contrast-rgb: 255,255,255;
  --app-color-secondary-shade: #46b0e0;
  --app-color-secondary-tint: #62ceff;

  --app-color-tertiary: #6a64ff;
  --app-color-tertiary-rgb: 106,100,255;
  --app-color-tertiary-contrast: #ffffff;
  --app-color-tertiary-contrast-rgb: 255,255,255;
  --app-color-tertiary-shade: #5d58e0;
  --app-color-tertiary-tint: #7974ff;

  --app-color-success: #20B772;
  --app-color-success-rgb: 47,223,117;
  --app-color-success-contrast: #000000;
  --app-color-success-contrast-rgb: 0,0,0;
  --app-color-success-shade: #29c467;
  --app-color-tag-green: rgba(60, 183, 114, .2); //#3cb772;
  --app-color-success-tint: #44e283;

  --app-color-warning: #ffd534;
  --app-color-warning-rgb: 255,213,52;
  --app-color-warning-contrast: #000000;
  --app-color-warning-contrast-rgb: 0,0,0;
  --app-color-warning-shade: #e0bb2e;
  --app-color-warning-tint: #ffd948;

  --app-color-danger: #ff4961;
  --app-color-danger-rgb: 255,73,97;
  --app-color-danger-contrast: #ffffff;
  --app-color-danger-contrast-rgb: 255,255,255;
  --app-color-danger-shade: #e04055;
  --app-color-danger-tint: #ff5b71;

  --app-color-dark: #f4f5f8;
  --app-color-dark-rgb: 244,245,248;
  --app-color-dark-contrast: #000000;
  --app-color-dark-contrast-rgb: 0,0,0;
  --app-color-dark-shade: #d7d8da;
  --app-color-dark-tint: #f5f6f9;

  --app-color-medium: #989aa2;
  --app-color-medium-rgb: 152,154,162;
  --app-color-medium-contrast: #000000;
  --app-color-medium-contrast-rgb: 0,0,0;
  --app-color-medium-shade: #86888f;
  --app-color-medium-tint: #a2a4ab;

  --app-color-light: #222428;
  --app-color-light-bright: #343131;
  --app-color-light-rgb: 34,36,40;
  --app-color-light-contrast: #ffffff;
  --app-color-light-contrast-rgb: 255,255,255;
  --app-color-light-shade: #222428;
  --app-color-light-tint: #383a3e;
  --app-color-light-opacity: rgba(255,255,255,.95);

  --svg-invert: invert(1);

  .card--holder .card.active,
  .card--holder .card:hover,
  .message--conversation--list div.sent p,
  .MuiButton-contained.Mui-disabled,
  .conversation-input-holder .conversation-input .conversation-input-textarea textarea,
  .MuiInputBase-input,
  .card--options .card--options--trash,
  .card--holder .card.active:hover,
  .card--options .card--options--flag {
    color: var(--app-color-light-contrast);
  }
  input[type=text],
  input[type=text]:-webkit-autofill,
  input[type=text]:-webkit-autofill:hover,
  input[type=text]:-webkit-autofill:focus,
  input[type=text]:-webkit-autofill:active,
  input[type=password],
  input[type=password]:-webkit-autofill,
  input[type=password]:-webkit-autofill:hover,
  input[type=password]:-webkit-autofill:focus,
  input[type=password]:-webkit-autofill:active,
  .Mui-focused{
    -webkit-transition-delay: 99999s;
    color: var(--app-color-light-contrast);
    -webkit-text-fill-color: var(--app-color-light-contrast);

    background-color: var(--app-color-light-shade) !important;
    -webkit-box-shadow: var(--app-color-primary-tint);
    transition: background-color 5000s ease-in-out 0s;
    border:1px solid var(--app-color-light-shade) !important;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-disableElevation,
  .MuiButtonBase-root.MuiButton-contained.MuiButton-containedPrimary,
  .MuiButtonBase-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    background-color:var(--app-color-light) !important;
    color: var(--app-color-light-contrast);
    border:1px solid var(--app-color-primary-tint);
  }

  .login .login__content {
    border-color:var(--app-color-primary-tint);
    background: rgba(255,255,255,.04);
  }

}

.dark {
  @include dark-mode();
}

@media (prefers-color-scheme: dark) {
  body {
    @include dark-mode();
  }
}
