@import './Layout.scss';
@import '../../Theme';

.login__header {
}

.login__content {

}


@media only screen and (max-width: $breakpoint-sm) {
  .login .login__content{
    max-width: 100vw;
    width: auto;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    transform: translate(0);
    padding: 50px 20px 30px;
  }
}
