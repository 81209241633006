@import './screens';

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner-eclipse {
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 25%;
    left: 41.5%;
    width: 100px;
    height: 100px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid theme-color('white');
    border-top-color: theme-color('primary');
    animation: spinner .6s linear infinite;

    @media (min-width: $screen-lg) {
      left: 50%;
    }
  }
}

.spinner-eclipse-centered:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: theme-color('primary');
  animation: spinner .6s linear infinite;
}
